import image_0000 from "../assets/image3/white00.webp";
import image_0001 from "../assets/image3/white01.webp";
import image_0002 from "../assets/image3/white02.webp";
import image_0003 from "../assets/image3/white03.webp";
import image_0004 from "../assets/image3/white04.webp";
import image_0005 from "../assets/image3/white05.webp";
import image_0006 from "../assets/image3/white06.webp";
import image_0007 from "../assets/image3/white07.webp";
import image_0008 from "../assets/image3/white08.webp";
import image_0009 from "../assets/image3/white09.webp";
import image_0010 from "../assets/image3/white10.webp";
import image_0011 from "../assets/image3/white11.webp";
import image_0012 from "../assets/image3/white12.webp";
import image_0013 from "../assets/image3/white13.webp";
import image_0014 from "../assets/image3/white14.webp";
import image_0015 from "../assets/image3/white15.webp";
import image_0016 from "../assets/image3/white16.webp";
import image_0017 from "../assets/image3/white17.webp";
import image_0018 from "../assets/image3/white18.webp";
import image_0019 from "../assets/image3/white19.webp";
import image_0020 from "../assets/image3/white20.webp";
import image_0021 from "../assets/image3/white21.webp";
import image_0022 from "../assets/image3/white22.webp";
import image_0023 from "../assets/image3/white23.webp";
import image_0024 from "../assets/image3/white24.webp";
import image_0025 from "../assets/image3/white25.webp";
import image_0026 from "../assets/image3/white26.webp";
import image_0027 from "../assets/image3/white27.webp";
import image_0028 from "../assets/image3/white28.webp";
import image_0029 from "../assets/image3/white29.webp";
import image_0030 from "../assets/image3/white30.webp";
import image_0031 from "../assets/image3/white31.webp";
import image_0032 from "../assets/image3/white32.webp";
import image_0033 from "../assets/image3/white33.webp";
import image_0034 from "../assets/image3/white34.webp";
import image_0035 from "../assets/image3/white35.webp";
import image_0036 from "../assets/image3/white36.webp";
import image_0037 from "../assets/image3/white37.webp";
import image_0038 from "../assets/image3/white38.webp";
import image_0039 from "../assets/image3/white39.webp";
import image_0040 from "../assets/image3/white40.webp";
import image_0041 from "../assets/image3/white41.webp";
import image_0042 from "../assets/image3/white42.webp";
import image_0043 from "../assets/image3/white43.webp";
import image_0044 from "../assets/image3/white44.webp";
import image_0045 from "../assets/image3/white45.webp";
import image_0046 from "../assets/image3/white46.webp";
import image_0047 from "../assets/image3/white47.webp";
import image_0048 from "../assets/image3/white48.webp";
import image_0049 from "../assets/image3/white49.webp";
import image_0050 from "../assets/image3/white50.webp";
import image_0051 from "../assets/image3/white51.webp";
import image_0052 from "../assets/image3/white52.webp";
import image_0053 from "../assets/image3/white53.webp";
import image_0054 from "../assets/image3/white54.webp";
import image_0055 from "../assets/image3/white55.webp";
import image_0056 from "../assets/image3/white56.webp";
import image_0057 from "../assets/image3/white57.webp";
import image_0058 from "../assets/image3/white58.webp";
import image_0059 from "../assets/image3/white59.webp";
import image_0060 from "../assets/image3/white60.webp";
import image_0061 from "../assets/image3/white61.webp";
import image_0062 from "../assets/image3/white62.webp";
import image_0063 from "../assets/image3/white63.webp";
// import image_0064 from "../assets/image3/white64.webp";
// import image_0065 from "../assets/image3/white65.webp";
// import image_0066 from "../assets/image3/white66.webp";
// import image_0067 from "../assets/image3/white67.webp";
// import image_0068 from "../assets/image3/white68.webp";
// import image_0069 from "../assets/image3/white69.webp";
// import image_0070 from "../assets/image3/white70.webp";
// import image_0071 from "../assets/image3/white71.webp";
// import image_0072 from "../assets/image3/white72.webp";
// import image_0073 from "../assets/image3/white73.webp";
// import image_0074 from "../assets/image3/white74.webp";
// import image_0075 from "../assets/image3/white75.webp";
// import image_0076 from "../assets/image3/white76.webp";
// import image_0077 from "../assets/image3/white77.webp";
// import image_0078 from "../assets/image3/white78.webp";
// import image_0079 from "../assets/image3/white79.webp";
// import image_0080 from "../assets/image3/white80.webp";
// import image_0081 from "../assets/image3/white81.webp";
// import image_0082 from "../assets/image3/white82.webp";
// import image_0083 from "../assets/image3/white83.webp";
// import image_0084 from "../assets/image3/white84.webp";
// import image_0085 from "../assets/image3/white85.webp";
// import image_0086 from "../assets/image3/white86.webp";
// import image_0087 from "../assets/image3/white87.webp";
// import image_0088 from "../assets/image3/white88.webp";
// import image_0089 from "../assets/image3/white89.webp";
// import image_0090 from "../assets/image3/white90.webp";


export const iphoneimages = [
  image_0000,
  image_0001,
  image_0002,
  image_0003,
  image_0004,
  image_0005,
  image_0006,
  image_0007,
  image_0008,
  image_0009,
  image_0010,
  image_0011,
  image_0012,
  image_0013,
  image_0014,
  image_0015,
  image_0016,
  image_0017,
  image_0018,
  image_0019,
  image_0020,
  image_0021,
  image_0022,
  image_0023,
  image_0024,
  image_0025,
  image_0026,
  image_0027,
  image_0028,
  image_0029,
  image_0030,
  image_0031,
  image_0032,
  image_0033,
  image_0034,
  image_0035,
  image_0036,
  image_0037,
  image_0038,
  image_0039,
  image_0040,
  image_0041,
  image_0042,
  image_0043,
  image_0044,
  image_0045,
  image_0046,
  image_0047,
  image_0048,
  image_0049,
  image_0050,
  image_0051,
  image_0052,
  image_0053,
  image_0054,
  image_0055,
  image_0056,
  image_0057,
  image_0058,
  image_0059,
  image_0060,
  image_0061,
  image_0062,
  image_0063,
  // image_0064,
  // image_0065,
  // image_0066,
  // image_0067,
  // image_0068,
  // image_0069,
  // image_0070,
  // image_0071,
  // image_0072,
  // image_0073,
  // image_0074,
  // image_0075,
  // image_0076,
  // image_0077,
  // image_0078,
  // image_0079,
  // image_0080,
  // image_0081,
  // image_0082,
  // image_0083,
  // image_0084,
  // image_0085,
  // image_0086,
  // image_0087,
  // image_0088,
  // image_0089,
  // image_0090,
];
