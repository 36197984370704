import React,{useEffect,useState} from "react";
import {Link} from "react-router-dom";
import fb from "../Images/icons/fb.svg";
import insta from "../Images/icons/insta.svg";
import twitter from "../Images/icons/twitter.svg";
import youtube from "../Images/icons/youtube.svg";
import axios from "axios";

function Footer() {
  const [sm, setSm] = useState([]);
  useEffect(() => {
    async function fetchSm() {
      const { data } = await axios.get("/api/socialmd/");
      setSm(data);
      // setProducts(data);
    }
    fetchSm();
  }, []);
  return (
    <footer className="text-center foo flex-bottom bg-black text-white py-lg-5" height="">
    <div className="container-lg">
      <section className="mb-2 footer_sections">
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none" to="">© SEWELOPARIS 2023</Link>
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none" to="../Privacy">PRIVACY POLICY</Link>
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none" to="../Cookies">COOKIE POLICY</Link>
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none" to="../Terms_condition">TERMS & CONDITIONS</Link>
        <Link  className="text-white text-decoration-none " to="../Terms_of_use">TERMS OF USE</Link>
      </section>
      <section className="mb-2 footer_sections">
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none " to="../Contactus">CONTACT US</Link>
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none " to="../Openingsoon">BOUTIQUE</Link>
        <Link style={{marginRight:'1rem'}} className="text-white text-decoration-none " to="../FAQ">FAQ</Link>
        <Link  className="text-white text-decoration-none mx-1" to="../Blog">BLOG</Link>
      </section>
      <section className="footer_sections">
        <a className="social mx-1" href={sm.facebook} target="_blank" rel="noopener noreferrer"><img height={25} src={fb} alt=""/></a>
        <a className="social mx-1" href={sm.insta} target="_blank" rel="noopener noreferrer"><img height={25} src={insta} alt=""/></a>
        <a className="social mx-1" href={sm.twiter} target="_blank" rel="noopener noreferrer"><img height={25} src={twitter} alt=""/></a>
        <a className="social mx-1" href={sm.youtube} target="_blank" rel="noopener noreferrer"><img height={25} src={youtube} alt=""/></a>
      </section>
    </div>
  </footer>
  );
}

export default Footer;
