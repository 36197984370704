import React from "react";
import Navbar from "../includes/Navbarwhite";
import bg1 from "../Images/coming/women.webp";
import GoToTop from "./scrolltop";
import withPreloader from './indexx';
import Footer from "../includes/footer";

function women() {
  return (
    <>
      <Navbar />
      <div className="">
      <section className=" position-relative"  style={{width:"100vw",height:"100%",overflow:"hidden"}}>
        <img loading="lazy" className="" style={{objectFit:"cover",minHeight:"100vh",width:"100vw"}} src={bg1} alt="" />
        <div className="position-absolute top-50 start-50 translate-middle">
          <h1  className="text-white h1ph">WOMEN'S BAGS</h1>
          <h1 className="text-white text-center">Coming Soon</h1>
        </div>
      </section>
      </div>
      <Footer />
      <GoToTop />
    </>
  );
}

export default withPreloader(women);
