import React from "react";
import Navbar from "../includes/Navbarwhite";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import GoToTop from "./scrolltop";

import withPreloader from './indexx';
import Footer from "../includes/footer";

const StyledCrossRevealContainer = styled.section`
  // @media screen and (min-width: 601px) and (max-width: 915px) {
  //     position: relative;
  //     padding-inline: 15vh;
  // }
  // @media screen and (min-width: 915px) and (max-width: 1200px) {
  //     position: relative;
  //     padding-inline: 20vh;
  // }
  // @media screen and (min-width: 1200px) and (max-width: 1300px) {
  //     position: relative;
  //     padding-inline: 25vh;
  // }
  // @media screen and (min-width: 1300px) and (max-width: 1400px) {
  //     position: relative;
  //     padding-inline: 30vh;
  // }
  // @media screen and (min-width: 1400px) and (max-width: 1450px) {
  //     position: relative;
  //     padding-inline: 35vh;
  // }
  // @media screen and (min-width: 1450px) and (max-width: 1550px) {
  //     position: relative;
  //     padding-inline: 40vh;
  // }
,  //   background-position: "center"6,7,12,13,18,20,16,17,21
  //   background-size: "cover",
  //   height: "100%",
  //   background-filter:'blur(8px)'
  background-color: white;

  .path {
    text-align: right;
    padding: 10px;
    color: LightGray;
  }
  p {
    text-align: center;
    margin-block: 20px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.012rem;
    font-size: 18px;
  }
  h2 {
    line-height: 1.4;
    font-weight: 450;
    letter-spacing: -0.022rem;
  }
  .mainhead {
    text-align: center;
  }
  .update {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 20px;
  }
  .policy_card {
  }
  .information_we_collect {
    text-align: center;
  }
  .hoe_we_use_your_information {
    text-align: center;
  }
  .security_of_your_information {
    text-align: center;
  }
  .change_to_this_policy {
    text-align: center;
  }
  .contactus {
    text-align: center;
  }
`;
function Cookies() {
  return (
    <div>
      <Navbar />
      <StyledCrossRevealContainer className="mb-0">
        <div className="policy_card container">
          <h6 className="path">HOME / COOKIE POLICY</h6>
          <h1 className="mainhead">COOKIE POLICY</h1>
          <p className="update">Last Updated : 22/03/2023 </p>
          <p className="top_content">
            &nbsp;&nbsp;&nbsp;&nbsp;At seweloparis.com we use cookies and
            similar technologies to enhance your experience and to understand
            how you interact with our website. This Cookie Policy explains what
            cookies are, how we use them, and your choices regarding their use.
          </p>
          <h2 className="information_we_collect">What are Cookies?</h2>
          <p className="second_contend">
            Cookies are small text files that are placed on your device when you
            visit a website. They are used to store information about your
            preferences, login information, and browsing history. Cookies are
            widely used by websites to enhance user experience, provide
            analytics, and deliver personalized content and advertisements.
          </p>
          <h2 className="hoe_we_use_your_information">
            Types of Cookies We Use
          </h2>
          <p className="third_contend">
            We use two types of cookies on our website: first-party cookies and
            third-party cookies.
          </p>
          <p className="third_contend">
            First-party cookies are created and controlled by us. We use these
            cookies to provide you with a personalized experience on our
            website, remember your preferences, and keep you logged in.
          </p>
          <p className="third_contend">
            Third-party cookies are created and controlled by third-party
            providers, such as Google Analytics or social media platforms. We
            use these cookies to collect data about your behavior on our
            website, such as which pages you visit and how long you stay on each
            page. This data helps us improve our website&#39;s performance and
            provide you with personalized content and advertisements.
          </p>
          <h2 className="security_of_your_information">
            Your Choices Regarding Cookies
          </h2>
          <p className="forth_contend">
            You can choose whether or not to accept cookies on our website. Most
            web browsers automatically accept cookies, but you can usually
            modify your browser settings to decline cookies if you prefer.
            However, disabling cookies may limit your ability to use certain
            features of our website.
          </p>
          <p className="forth_contend">
            If you wish to opt-out of third-party cookies, you can do so by
            visiting the Network Advertising Initiative&#39;s opt-out page or
            the Digital Advertising Alliance&#39;s opt-out page.
          </p>
          <h2 className="change_to_this_policy">Changes to This Policy</h2>
          <p className="fifth_contend">
            We may update this Cookie Policy from time to time. If we make any
            material changes to this policy, we will notify you by email or by
            posting a notice on our website.
          </p>
          <h2 className="contactus">Contact Us</h2>
          <p className="sixth_contend mb-0 pb-5">
            If you have any questions or concerns about this Cookie Policy or
            our practices, please contact us at info@seweloparis.com
          </p>
        </div>
      </StyledCrossRevealContainer>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default withPreloader(Cookies);
