import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import topbg from "../Images/product/topbg.jpg";
import Navbar from "../includes/Navbarwhite3";
import { CustomButtonGroupAsArrows } from "../includes/Carouselarrow";
// import Preloader from '../includes/preloader';
import axios from "axios";
import Items from "../includes/Items1";
import { Col, Row } from "react-bootstrap";
import GoToTop from "./scrolltop";
import CrossRevealSection from "../includes/CrossRevealSection1";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import bg from "../assets/green.jpg";
import ThreeSixty from "../utils/threesixty";
// import { useLocation } from "react-router-dom";
import Enquiry from "./Enquiry";
import Carouselss from "./carousels";
// import bootstrap from "react-bootstrap"
import withPreloader from './indexx';
import Footer from "../includes/footer";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Product() {

  // const routePath = useLocation();
  // const onTop = () => {
  //   window.scrollTo(0, 0);
  // };
  // useEffect(() => {
  //   onTop();
  // }, [routePath]);

  const [product, setProduct] = useState([]);
  const pramas = useParams();
  // console.log(pramas)
  // const autoPlay = useRef(null);
  useEffect(() => {
    async function fetchProduct() {
      const { data } = await axios.get(`/api/products/${pramas.name}`);
      // console.log(data);
      setProduct(data);
      // const preev=document.getElementById('prev')
      new ThreeSixty(document.getElementById("threesixtyph"), {
        image: data.thumb360,
        width: responsive.items,
        height: 300,
        count: 36,
        perRow: 4,
        speed: 100,
        prev: document.getElementById("prevph"),
        next: document.getElementById("nextph"),
        // autoplay: document.getElementById('autoplay'),
        swipeTarget: document.getElementById("wrapper"),
        inverted: true,
      });
      new ThreeSixty(document.getElementById("threesixtylg"), {
        image: data.thumb360,
        width: 750,
        height: 480,
        count: 36,
        perRow: 4,
        speed: 100,
        prev: document.getElementById("prevlg"),
        next: document.getElementById("nextlg"),
        autoPlay: document.getElementById("autoplay"),
        stop: document.getElementById("stop"),
        swipeTarget: document.getElementById("wrapper"),
        inverted: true,
      });
      // autoPlay.current.addEventListener('click',(e) => threesixtyph.play())
      // threesixty.play();
    }
    fetchProduct();
  }, [pramas.name]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    async function fetchProduct() {
      const { data } = await axios.get("/api/products/");
      const filteredProducts = data.filter(product => product.brand === "MARQUIS");
      setProducts(filteredProducts);
      // setProducts(data);
    }
    fetchProduct();
  }, []);
  return (
    <div>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p style={{ textAlign: "center", fontSize: ".7rem" }}>
                Drag, use keyboard arrows (&larr; & &rarr;) to rotate image.
                <br />
              </p>
              <div className="wrapper">
                {/* <p>
                  <a className="g-hub" href="https://github.com/mladenilic/threesixty" target="_blank">Check out ThreeSixty.js on Github</a>
                </p> */}
                <div id={"threesixtylg"}></div>
                <div id="threesixtyph"></div>
                <div className="buttons-wrapper">
                  <button className="btn btn-light" id={"autoplay"}>
                    <i className="bi bi-arrow-clockwise"></i>
                  </button>
                  <button className="btn btn-light" id={"stop"}>
                    <i className="bi bi-stop-circle"></i>
                  </button>
                  <button className="btn btn-light" id={"prevlg"}>
                    <i className="bi bi-chevron-double-left"></i>
                  </button>
                  <button className="btn btn-light" id={"nextlg"}>
                    <i className="bi bi-chevron-double-right"></i>
                  </button>
                  {/* <button className="btn btn-light" id={"prev"+product.id}><i className="bi bi-chevron-double-left"></i></button> */}
                  <button className="btn btn-light" id={"prevph"}>
                    <i className="bi bi-chevron-double-left"></i>
                  </button>
                  <button className="btn btn-light" id={"nextph"}>
                    <i className="bi bi-chevron-double-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {products.map((product) => (
        <div key={product.id}>
          <Enquiry 
          face={product.id} />
        </div>
      ))}
      <section
        className=" position-relative"
        // style={{
        //   backgroundImage: `url(${product.image})`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   height: "125vh",
        //   width: "100vw",
        // }}
      >
        <Navbar />
        <CrossRevealSection
          id="crossrev"
          face={product.image}
          discr={product.disc}
          name={product.nameimg}
          perfumer={product.perfumer}
          perfumername={product.perfumername}
          perfumersec={product.perfumersecname}
          crossreveal={"xPercent"}
        />
        <Row id="topimages">
          <Col
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{
              backgroundImage: `url(${product.image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
              // width: "100vw",
            }}
          >
          </Col>
          <Col style={{ height: "100%" }}>
            <p className="pt-5 mx-3" style={{ textAlign: "right" }}>
              Sewelo/ Our Fragrance/ Marquis Colletions
            </p>
            <img
              loading="lazy"
              id="productnameimage"
              className="mt-5 mx-3"
              src={product.nameimg}
              style={{
                display: "flex",
                filter: "invert(1)",
                // float: "right",
                objectFit: "contain",
                width: "70vw ",
                // marginLeft: "30vw",
              }}
              alt=""
            />
            <p
              id="productdisc"
              className="mx-3 mb-5 pt-5"
              style={{ textAlign: "justify" }}
            >
              {product.disc}
            </p>
            <div
              className="position-absolute  px-3 bottom-0  end-0 my-3 d-lg-block "
              style={{ height: "60px", textAlign: "center" }}
            >
              <p className="pfor_profil m-0">
                <img
                  loading="lazy"
                  src={product.perfumer}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    left: 0,
                  }}
                  alt="loading"
                />
              </p>

              <p className="my-0" style={{ fontSize: ".5rem" }}>
                Perfumer
              </p>
              <p className="my-0" style={{ fontSize: ".6rem" }}>
                {product.perfumername}
              </p>
              <p className="" style={{ fontSize: ".5rem" }}>
                {product.perfumersecname}
              </p>
            </div>
            <button
              type="button"
              className="my-4 px-2 mx-3  btn btn-outline-dark rounded-0"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <p style={{ margin: 0, fontSize: "10px" }}>VIEW 360&deg;</p>
            </button>
          </Col>
        </Row>
      </section>

      <section>
        <Carouselss />
      </section>


      <video style={{minWidth:'100vw',backgroundColor:"white" }}
                id="videoElementother"
                className="videoTag"
                src={product.video}
                loading="lazy"
                loop 
                muted
                autoPlay
                disablePictureInPicture
                playsInline
                poster={product.poster}
      />
      <video style={{minWidth:'100vw',backgroundColor:"white" }}
          id="videoElementsafari"
          className="videoTag"
          src={product.video}
          loading="lazy"
          muted
          autoPlay
          playsInline
          loop 
          disablePictureInPicture
          poster={product.poster}
      />
      <section className="my-5 py-4">
        <div className="position-relative container border border-dark w-100  mx-auto my-5 d-flex">
          <h6 className="bg-white border fonttext text-center border-dark py-3 px-md-4 position-absolute top-100 start-50 translate-middle phone_htext">
            MORE MARQUIS COLLECTIONS
          </h6>
        </div>
      </section>
      <section id="section_carousel" className="">
        <div className="container mb-5" style={{ position: "relative" }}>
          <Carousel
            responsive={responsive}
            swipeable={false}
            arrows={false} 
            selectedIndex={1}
            rewind={true}
            autoPlay={true}
            // ref={carouselRef}
            renderButtonGroupOutside={true}
            customButtonGroup={<CustomButtonGroupAsArrows />}
            focusOnSelect={false}
            draggable={false}
            showDots={true}
            infinite={false}
            keyBoardControl={false}
            rewindWithAnimation={true}
            autoPlaySpeed={2000}
          >
            {products.map((product) => (
              
              <Col
                style={{ overflowX: "hidden" }}
                className="px-5"
                key={product.id}
              >
                <Enquiry 
                face={product.id} />
                <Items product={product} />
              </Col>
            ))}
          </Carousel>
        </div>
      </section>
      <Footer />
      <GoToTop />
    </div>
  );
}
export default withPreloader(Product);
