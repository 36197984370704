import React, { useState,useEffect } from "react";
import Navbar from "../includes/Navbarwhite";
import Cimage from "../Images/logo/whiteLogo.svg";
import Cbg from "../Images/coming/contact.webp";
import axios from "axios";
import GoToTop from "./scrolltop";
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-number-input/style.css'
import PhoneInput,{ isValidPhoneNumber,isPossiblePhoneNumber  } from 'react-phone-number-input'
import { isEmail } from 'validator';
import withPreloader from './indexx';
import Footer from "../includes/footer";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

function Contactus() {

  const [name, setName] = useState("");
  const [sender, setSender] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [validnumb, setValidnumb] = useState(false)
  const [validmail, setValidmail] = useState(false)
  // const [messages, setMessages] = useState("");
  const [verified, setVerified] = useState(false);
  // const [gcaptcha, setGcaptcha] = useState("")
  // const [error,setError] = useState("");
  function onChange(value) {
    if (value === null) {
      setVerified(false)
    } else {
      // setGcaptcha(value)
      setVerified(true)
    }
    
    // console.log("Captcha value:", value);
  }
  useEffect(() => {
    console.log(validmail,validnumb)
    number && isPossiblePhoneNumber(number)  ? setValidnumb(true) : setValidnumb(false);

    
    if (isEmail(sender)) {
      // Email is valid
      setValidmail(true);
    } else {
      // Email is invalid
      setValidmail(false);
    }
  },[validmail, validnumb, number, sender]);
  // const captchaRef = useRef(null)
  const createNote = async (event) => {
    event.preventDefault();
    if (validnumb === false) {
      alert("Phone number is not valid.")
    }
    if (validmail === false) {
      alert("Email is not valid.")
    }
    // if (isEmail(sender)) {
    //   // Email is valid
    //   setValidmail(true);
    //   // Continue with further actions (e.g., submit the form)
    // } else {
    //   // Email is invalid
    //   setValidmail(false);
    //   alert("Email is not valid.")
    // }
      // console.log(response.status);
      if (validnumb&&validmail === true) {
        axios({
          method: "post",
          url: "/api/mail/",
          data: {
            name: name,
            sender: sender,
            message: message,
            number: number
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          },
        }).then((response) => {
          console.log(response.status);
          if (response.status === 201) {
            alert("mail sent successfully ")
            // console.log(response.status);
            }
        })
        .catch(response => {
            alert("HTTP_400_BAD_REQUEST Fill out all fields")
            console.log(response);
        });
        setName("");
        setSender("");
        setMessage("");
        setNumber("")
        }
    
  };
//   const verifyToken = async (token) => {

//     try{
//     let response = await axios.post(`http://localhost:4000/verify-token`,{
       
//     // secret:process.env.REACT_APP_SECRET_KEY,
//     secret:'6LcqKDslAAAAABYqwsNa2eM51p9XbgTh2zPxt4AQ',
//         token
//     },console.log(token));
//     return response.data;
//     }catch(error){
//     console.log("error ",error);
//     }
// }

//   const verifyToken = async (token) => {
    
//     try{
//     let response = await axios.post(`http://localhost:4000/verify-token`,{
       
//     // secret:process.env.REACT_APP_SECRET_KEY,
//     secret:'6LcqKDslAAAAABYqwsNa2eM51p9XbgTh2zPxt4AQ',
//         token
//     },console.log(token));
//     return response.data;
//     }catch(error){
//     console.log("error ",error);
//     }
// }


  return (
    <>
      <Navbar />
      <div id='contact-container' className="" style={{height:'100%'}}>
        <div className="row p-xl-5 p-md-4">
          <div className="col-xl-12">
            <div className="card border-0 rounded-0  overflow-hidden">
              <div className="card-body p-0">
                {/* style={{  background: 'linear-gradient(to right, #bdc3c7, #2c3e50)'}} */}
                <div className="row g-0  " style={{backgroundImage: `url(${Cbg})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: "100%",
                  backgroundFilter:'blur(8px)'
                  // height:"100vh"
                  }}>
                  <div className="col-lg-6  " style={{alignSelf:'center'}}>
                    <img loading="lazy" id='contactlogo'  src={Cimage} alt="loading" />
                    <p className="text-center text-light my-4">SCENTEGY US INC. 469 FASHION AVENUE,<br />
                      12TH FLOOR, NEW YORK, NY 10018, USA</p>
                      <div className="row px-4" id="contact_text">
                      <p className="text-center text-light"><i className="bi bi-person-lines-fill"> +1 630 346 7040</i></p>
                        <p className="text-center text-light"><i className="bi bi-envelope-at-fill"> info@seweloparis.com</i></p>
                        <p className="text-center text-light"><i className="bi bi-globe2"> seweloparis.com</i></p>
                        {/* <div className="col"><p className="text-center text-light"><i className="bi bi-person-lines-fill"> +1 630346 7040</i>  </p></div>
                        <div className="col"><p className="text-center text-light"><i className="bi bi-envelope-at-fill"> info@seweloparis.com</i> </p></div>
                        <div className="col"><p className="text-center text-light"><i className="bi bi-globe2"> seweloparis.com</i></p></div> */}
                      </div>
                      <div className="row px-4 my-0" id="contact_textlg">
                        <p className="text-center text-light"><i className="bi bi-person-lines-fill"> +1 630 346 7040</i></p>
                        <p className="text-center text-light"><i className="bi bi-envelope-at-fill"> info@seweloparis.com</i></p>
                        <p className="text-center text-light"><i className="bi bi-globe2"> seweloparis.com</i></p>
                      </div>
                  </div>
                  <div className="col-lg-6 p-4 mt-3" id="contactsection">
                    <div className="text-center">
                      <div className="h2 text-white fw-light py-2">
                        <h2>Contact Us</h2>
                      </div>
                    </div>
                    <form id="contactForm">
                    {/* {
                        error && <p className='textError'>Error!! {error}</p>
                    }
                    {
                        messages && <p className='textSuccess'>Success!! {messages}</p>
                    } */}
                      {/* <!-- Name Input --> */}
                      <div className="form-floating mb-2">
                        <input
                          className=" form-control  rounded-0"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          id="name"
                          type="text"
                          placeholder="Name"
                          data-sb-validations="required"
                          required
                        />
                        <label>Name</label>
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="name:required"
                        >
                          Name is required.
                        </div>
                      </div>

                      {/* <!-- Email Input --> */}
                      <div className="form-floating mb-2">
                        <input
                          className="form-control rounded-0"
                          value={sender}
                          onChange={(e) => setSender(e.target.value)}
                          name="email"
                          id="emailAddress"
                          type="email"
                          placeholder="Email Address"
                          data-sb-validations="required,email"
                          required
                        />
                        {sender ? <div className="text-light" >{isEmail(sender) ? '' : 'Email is not valid.'}</div> : ""}
                        <label>Email Address</label>
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="emailAddress:required"
                        >
                          Email Address is required.
                        </div>
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="emailAddress:email"
                        >
                          Email Address Email is not valid.
                        </div>
                      </div>

                      <div className="form-floating mb-2">
                        {/* <input
                          className="form-control rounded-0"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          name="number"
                          id="phonenumber"
                          type="tel"
                          placeholder="Phone Number"
                          data-sb-validations="required,phone"
                          required
                        /> */}
                        <PhoneInput className="form-control rounded-0 py-0 d-flex bg-light"
                          id="country_inputform"
                          placeholder="Enter phone number"
                          name="number"
                          value={number}
                          onChange={setNumber}
                          rules={{  required: true }}
                          // error={number ? (isValidPhoneNumber(number) ? undefined : 'Invalid phone number') : 'Phone number required'}
                          />{number ? <div className="text-light">{number && isValidPhoneNumber(number) ? '' : 'Phone number is not valid.'}</div> : ""}
                          
                          {/* Is valid: {number && isValidPhoneNumber(number) ? 'true' : 'false'} */}
                        {/* <label>Phone Number</label> */}
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="phonenumber:required"
                        >
                          Phone number is required.
                        </div>
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="Phonenumber:number"
                        >
                          Phone number is not valid.
                        </div>
                      </div>

                      {/* <!-- Message Input --> */}
                      <div className="form-floating mb-2 ">
                        <textarea
                          className="form-control rounded-0"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="message"
                          id="message"
                          type="text"
                          placeholder="Message"
                          data-sb-validations="required"
                          required
                        ></textarea>
                        <label>Message</label>
                        <div
                          className="invalid-feedback"
                          data-sb-feedback="message:required"
                        >
                          Message is required.
                        </div>
                      </div>

                      {/* <!-- Submit success message --> */}
                      <div className="d-none" id="submitSuccessMessage">
                        <div className="text-center">
                          <div className="fw-bolder">
                            Form submission successful!
                          </div>
                          <p>To activate this form, sign up at</p>
                          <a href="https://startbootstrap.com/solution/contact-forms">
                            https://startbootstrap.com/solution/contact-forms
                          </a>
                        </div>
                      </div>

                      {/* <!-- Submit error message --> */}
                      <div className="d-none" id="submitErrorMessage">
                        <div className="text-center text-danger">
                          Error sending message!
                        </div>
                      </div>
                      <div className="form-floating ">
                      {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}
                      <ReCAPTCHA sitekey='6LdIZkMlAAAAAJXNXfnp5jirs8oQNQvK1VKuhxYp'
                       onChange={onChange}
                       theme='dark'
                       className="g-recaptcha"
                      //  size="invisible"
                        />
                       
                      </div>
                      
                      {/* <!-- Submit button --> */}
                      <div className="d-grid ">
                      

                        <button
                          className="btn btn-secondary btn-lg rounded-0 "
                          value="Send"
                          onClick={createNote}
                          id="submitButton"
                          type="submit"
                          disabled={!verified}
                        >
                          Submit
                        </button>
                        
                      </div>
                    </form>
                    {/* <!-- End of contact form --> */}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
      <GoToTop />
    </>
  );
}

export default withPreloader(Contactus);
