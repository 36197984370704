// withPreloader.js
import React, { useState, useEffect } from 'react';
import Preloader from '../includes/preloader';

const withPreloader = (WrappedComponent) => {
  const WithPreloader = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulating a 3-second loading time
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
    }, []);

    return isLoading ? <Preloader /> : <WrappedComponent {...props} />;
  };

  return WithPreloader;
};

export default withPreloader;
