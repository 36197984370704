import React from "react";
import Navbar from "../includes/Navbarwhite";
import bg1 from "../Images/coming/assher.webp";
import name from "../Images/coming/Sewelo Brand Names-02.webp"
import GoToTop from "./scrolltop";
import withPreloader from './indexx';
import Footer from "../includes/footer";

function Asscher() {
  return (
    <>
      <Navbar />
      <div className="">
      <section className=" position-relative" style={{width:"100vw",height:"100%",overflow:"hidden"}}>
        <img loading="lazy" style={{objectFit:"cover",minHeight:"100vh",width:"100%"}} src={bg1} alt="" />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <img loading="lazy" src={name} className="w-75" style={{filter: 'invert(1)'}} alt="" />
          <h1 className="text-white text-center">Coming soon</h1>
        </div>
      </section>
      </div>
      <Footer />
      <GoToTop />
    </>
  );
}

export default withPreloader(Asscher);
