import React from "react";
import Navbar from "../includes/Navbarwhite";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import GoToTop from "./scrolltop";
import withPreloader from './indexx';
import Footer from "../includes/footer";


const StyledCrossRevealContainer = styled.section`
  // @media screen and (min-width: 601px) and (max-width: 915px) {
  //     position: relative;
  //     padding-inline: 15vh;
  // }
  // @media screen and (min-width: 915px) and (max-width: 1200px) {
  //     position: relative;
  //     padding-inline: 20vh;
  // }
  // @media screen and (min-width: 1200px) and (max-width: 1300px) {
  //     position: relative;
  //     padding-inline: 25vh;
  // }
  // @media screen and (min-width: 1300px) and (max-width: 1400px) {
  //     position: relative;
  //     padding-inline: 30vh;
  // }
  // @media screen and (min-width: 1400px) and (max-width: 1450px) {
  //     position: relative;
  //     padding-inline: 35vh;
  // }
  // @media screen and (min-width: 1450px) and (max-width: 1550px) {
  //     position: relative;
  //     padding-inline: 40vh;
  // }
,  //   background-position: "center"6,7,12,13,18,20,16,17,21
  //   background-size: "cover",
  //   height: "100%",
  //   background-filter:'blur(8px)'
  background-color: white;

  .path {
    text-align: right;
    padding: 10px;
    color: LightGray;
  }
  p {
    text-align: left;
    margin-block: 20px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.012rem;
    font-size: 18px;
  }
  h4 {
    text-align:left;
    line-height: 1.4;
    font-weight: 450;
    letter-spacing: -0.022rem;
  }
  .mainhead {
    text-align: center;
  }
  .update {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 20px;
  }
  .policy_card {
  }
  .information_we_collect {
    text-align: center;
  }
  .hoe_we_use_your_information {
    text-align: center;
  }
  .security_of_your_information {
    text-align: center;
  }
  .change_to_this_policy {
    text-align: center;
  }
  .contactus {
    text-align: center;
  }
`;
function Faq() {
  return (
    <div>
      <Navbar />
      <StyledCrossRevealContainer className="mb-0">
        <div className="policy_card container">
          <h6 className="path">HOME / FAQ</h6>
          <h1 className="mainhead">Frequently Asked Questions (FAQ) for seweloparis.com</h1>
          <p className="update">Last Updated : 22/03/2023 </p>
          <h4 className="information_we_collect">Q: What is seweloparis.com?</h4>
          <p className="top_content">
          A: seweloparis.com is a website dedicated to providing information about the brand “Sewelo”.
We offer company information, product information, and other resources to help you learn
more about Sewelo and its products.
          </p>
          <h4 className="information_we_collect">Q: Who is seweloparis.com for?</h4>
          <p className="second_contend">
          A: Our website is for anyone who is interested in Sewelo perfumes, whether you are a seasoned
perfume enthusiast or just starting to learn about fragrances.
          </p>
          <h4 className="hoe_we_use_your_information">Q: Is your website affiliated with any other perfume brands?</h4>
          <p className="third_contend">
          A: No, we are an independent website and are not affiliated with any other specific perfume
brands.
          </p>
          {/* <p className="third_contend">
            We reserve the right to modify or discontinue our website at any
            time without prior notice. We also reserve the right to deny access
            to our website to anyone who violates this Agreement.
          </p> */}
          {/* <p className="third_contend">
            Third-party cookies are created and controlled by third-party
            providers, such as Google Analytics or social media platforms. We
            use these cookies to collect data about your behavior on our
            website, such as which pages you visit and how long you stay on each
            page. This data helps us improve our website&#39;s performance and
            provide you with personalized content and advertisements.
          </p> */}
          <h4 className="security_of_your_information">
          Q: Do you sell perfumes on your website?
          </h4>
          <p className="forth_contend">
          A: No, we do not sell perfumes. Our website is purely informational and is designed to help you
make informed choices when purchasing Sewelo perfumes.
          </p>
          {/* <p className="forth_contend">
            If you wish to opt-out of third-party cookies, you can do so by
            visiting the Network Advertising Initiative&#39;s opt-out page or
            the Digital Advertising Alliance&#39;s opt-out page.
          </p> */}
          <h4 className="change_to_this_policy">Q: How do I choose the right perfume for me?</h4>
          <p className="fifth_contend">
          A: Choosing the right perfume can be a personal and subjective process. We offer a range of
resources to help you make an informed choice, including articles on different types of
perfumes, fragrance families, and fragrance notes.
          </p>
          <h4 className="contactus">Q: Can you recommend a perfume for me?</h4>
          <p className="sixth_contend  ">
          A: We cannot recommend specific perfumes, as everyone&#39;s tastes and preferences are
different. However, we can offer general advice and guidance on how to choose a perfume that
is right for you. Please talk with our perfume expert at all our stores.
          </p>
          <h4 className="contactus">Q: How do I apply perfume correctly?</h4>
          <p className="sixth_contend  ">
          A: There are different techniques for applying perfume, depending on the type of perfume and
your personal preferences. Talk with our perfume expert in the store to know more.
          </p>
          <h4 className="contactus">Q: How long does Sewelo perfume last?</h4>
          <p className="sixth_contend  ">
          A: The longevity of a perfume can vary depending on factors such as the concentration of the
fragrance, the individual&#39;s body chemistry, and the environment.
          </p>
          <h4 className="contactus">Q: How do I store my Sewelo perfume?</h4>
          <p className="sixth_contend  ">
          A: Perfume should be stored in a cool, dry place away from direct sunlight and heat. Talk
directly to our perfume experts in store for tips on how to properly store your perfume to help
it last longer.
          </p>

          <h4 className="contactus">Q: How can I get in touch with seweloparis.com?</h4>
          <p className="sixth_contend pb-5">
          A: You can contact us by email at info@seweloparis.com. We are always happy to hear from
our readers and welcome any feedback or suggestions you may have.
          </p>
        </div>
      </StyledCrossRevealContainer>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default withPreloader(Faq);
