import React, { useState, useEffect} from "react";
import Navbar from "../includes/Navbar";
import Items from "../includes/Items11";
import { CustomButtonGroupAsArrows } from "../includes/Carouselarrow";
import { Col } from "react-bootstrap";
import axios from "axios";
import ContentSection from "../includes/ContentSection";
import VideoSequenceSection from "../includes/VideoSequenceSection";
import VideoSequenceSection1 from "../includes/VideoSequenceSection1";
import prod4 from "../Images/coming/Artboard12.jpg";
import prod5 from "../Images/coming/Artboard13.jpg";
import prod6 from "../Images/coming/Artboard14.jpg";
import GoToTop from "./scrolltop";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import withPreloader from './indexx';
import Footer from "../includes/footer";
// import VideoPlayer from "./reactvideoplayer1";

// import VideoPlayer from '../VideoPlayer';
// import VideoBanner from "./reactvideoplayer";

// import 'video-js.css';
// import ReactPlayer from 'react-player'


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Home() {
  // const sectionCarouselRef = useRef(null);

  // useEffect(() => {
  //   sectionCarouselRef.current.scrollIntoView({ behavior: 'smooth' });
  // }, []);


  const [products, setProduct] = useState([]);
  useEffect(() => {
    async function fetchProduct() {
      const { data } = await axios.get("/api/products/");
      const filteredProducts = data.filter(product => product.brand === "MARQUIS");
      setProduct(filteredProducts);
      // setProduct(data);
    }
    fetchProduct();
  }, []);
  const [home, setHome] = useState([]);
  useEffect(() => {
    async function fetchHome() {
      const { data } = await axios.get("/api/home/");
      setHome(data);
    }
    fetchHome();
  }, []);

  return (
    <div>
        <Navbar />

        {/* <VideoBanner
        video={home.video} /> */}
        {/* <div id="videoElementsafaris" style={{minWidth:'100vw',backgroundColor:"black",height:"100vh",overflow:"hidden" }}>
        <VideoPlayer videoUrl={home.video} />
        <VideoPlayer src={home.video} />
        </div> */}
        <video style={{minWidth:'100vw',backgroundColor:"black" }}
                  id="videoElementother"
                  className="videoTag"
                  src={home.video}
                  loading="lazy"
                  loop 
                  muted
                  preload="auto"
                  autoPlay
                  playsInline
                  poster={home.poster}
            />
            {/* <div id="videoElementsafari" style={{minWidth:'100vw',backgroundColor:"black",height:"100vh",overflow:"hidden" }}>
            <ReactPlayer
              className="videoTag"
              url={home.video}
              playing={true}
              muted={true}
              width='100%'
              height=''
              playsInline
              controls={false}
              loop={true}
            />
            </div> */}


            
        <video style={{minWidth:'100vw',backgroundColor:"black" }}
            id="videoElementsafari"
            className="videoTag"
            // ref={videoRef}
            src={home.video}
            loading="lazy"
            loop 
            muted
            autoPlay
            disablePictureInPicture
            playsInline
            poster={home.poster}
            // controls
      />

      {/* <section  id="firstvideosectionph" style={{position:"relative",height:"100vh",minWidth:'100vw'}}>
        <div id="just_for_space"></div>
        <img loading="lazy" className="imgsections" style={{width:'100vw',padding:'9vw'}} src={home.banner3} alt="...loading" />
        <hr
          className="mx-auto my-4 hr_for_conten_brake"
          style={{
            height: "1px",
            borderWidth: "0",
            color: "black",
            backgroundColor: "#000",
            width: "70vw",
          }}
        />
        <ContentSection image={home.banner3}  title={"SEWELO"} text={"Unleash your inner goddess with our aromatic scents."} />
      </section> */}

      <section id="firstvideosectionph" className="mx-5">
      <div className="flex-grow py-5">
        {/* Image Section */}
        <img className="img-fluid" src={home.banner3} alt="loading" />
      </div>
      <div className=" py-5 ">
      <ContentSection />
        {/* Content Section */}
        {/* <h1 className="mb-3">Title</h1>
        <p>Content goes here...</p> */}
      </div>
    </section>
      <section id="firstvideosection">
          <VideoSequenceSection1 />
      </section>
      <section >
          <VideoSequenceSection />
      </section>
      <section id="discover" className="position-relative">
      <div className="position-relative  mx-auto my-5 d-flex">
          <div className="mx-auto  border border-secondary border-2">
            <h2 className="px-4 pt-2" >MARQUIS </h2>
            <h6 className="bg-white px-2 position-absolute top-100 start-50 translate-middle">
              Collections
            </h6>
          </div>
        </div>
        <section id="section_carousel" name="section_carousel" className="">
        <div className="container"  style={{position:'relative'}}>
          {/* <p className="new">{totalItems}</p> */}
        <Carousel 
        responsive={responsive}
        swipeable={false}
        arrows={false} 
        selectedIndex={1}
        rewind={true}
        autoPlay={true}
        // ref={carouselRef}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
        focusOnSelect={false}
        draggable={false}
        showDots={true}
        infinite={false}
        keyBoardControl={false}
        rewindWithAnimation={true}
        autoPlaySpeed={2000}
        >
            {products.map((product) => (
            <Col   style={{overflowX:"hidden"}} className="px-5" key={product.id} >
              <Items product={product} />
            </Col>
          ))}
        </Carousel>
        </div>
        <hr
          className="mx-auto "
          style={{
            height: "1px",
            borderWidth: "0",
            color: "black",
            backgroundColor: "#000",
            width: "85%",
            // marginBottom:"100px",
            marginTop:"100px"
          }}
        />
       
        </section>
        <section id="section_ourstore"  className="my-5 position-relative">
        <img loading="lazy" id="img_ourstore" className="my-1 " src={home.banner2} alt="" />
        <div className="position-absolute top-50 start-50 translate-middle  text-center">
          <h3 id="our_store_h3" className="text-white h1ph">
            OUR STORE
          </h3>
          <h2 id="our_store_h2" className="text-white">COMING SOON</h2>
        </div>
      </section>
        <hr
          className="mx-auto "
          style={{
            height: "1px",
            borderWidth: "0",
            color: "black",
            backgroundColor: "#000",
            width: "85%",
            marginBottom:"100px",
            marginTop:"40px"
          }}
        />
        <h3 className="mt-5 text-center">DISCOVER</h3>
        <h3 className="text-center">OUR BRANDS</h3>
        <hr
          className="mx-auto mt-0"
          style={{
            height: "2px",
            borderWidth: "0",
            color: "black",
            backgroundColor: "#000",
            width: "150px",
          }}
        />
      </section>
      <section id="section_brands">
        <div className=" mb-5 mx-md-5">
          <div className="row my-5 ">
            <div className="col-lg-4 px-4">
              <img loading="lazy" className="w-100" src={prod4} alt="" />
              <div className="position-relative  mx-auto my-5 d-flex">
                <div className="mx-auto  border border-secondary border-2">
                  <h4 className="px-4 mt-2">MARQUIS </h4>
                  <h6 className="bg-white px-2 position-absolute top-100 start-50 translate-middle">
                    Collections
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-4">
              <img loading="lazy" className="w-100" src={prod5} alt="" />
              <div className="position-relative  mx-auto my-5 d-flex">
                <div className="mx-auto  border border-secondary border-2">
                  <h4 className="px-4 mt-2">ASSCHER </h4>
                  <h6 className="bg-white px-2 position-absolute top-100 start-50 translate-middle">
                    Collections
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-4">
              <img loading="lazy" className="w-100" src={prod6} alt="" />
              <div className="position-relative  mx-auto my-5 d-flex">
                <div className="mx-auto  border border-secondary border-2">
                  <h4 className="px-4 mt-2">BAGUETTE </h4>
                  <h6 className="bg-white px-2 position-absolute top-100 start-50 translate-middle">
                    Collections
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <GoToTop />
      
    </div>
    );
}
export default withPreloader(Home);
