import React from 'react';
import favicon from '../assets/favicon.svg'

const Preloader = () => {
  return (
    // <div className="preloader">
    //   <div className="spinner"></div>
    // </div>
    <div id="spinner" className="cont">
    <div className="loading"><img src={favicon} alt='..' /></div>
  </div> 
  );
};

export default Preloader;