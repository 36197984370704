import React from "react";
import styled from "styled-components";

import { SplitText } from "../utils/SplitText";

const StyledContentSection = styled.div`
  // position: absolute;
  
  // width:100vw;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // bottom:0;
  .content__wrapper {
    max-width: 980px;
    // background-color:black;
    // bottom:0;
    text-align:center;


`;

function ContentSection({title,text}) {
  return (
    <StyledContentSection className="styled_content_section">
      {/* <img className="imgsections pb-5 top-0" style={{width:'90vw'}} src={image} alt="" /> */}
      <div className="content__wrapper">
        {/* <div className="videosequence__text"  id="title_vitext"> */}
        {/* <img id='contactlogo'  src={Cimage} alt="loading" /> */}

        <h1 id="title_h2">SEWELÔ
        </h1>
        <h3 id="title_h3" className="pb-5">
          <SplitText>Indulge in the art of fragrance </SplitText>
          <SplitText>and elevate your senses.</SplitText>
          {/* <SplitText>that capture who you truly are.</SplitText> */}
        </h3>
        {/* <Link to="" className="videosequence__button">
              Download
            </Link> */}
        {/* </div> */}
        {/* <h2 id="title_h2">{props.title}</h2>
        <h3 id="title_h3">
          
        </h3> */}
      </div>
    </StyledContentSection>
  );
}
export default ContentSection;
