import React from "react";
import Navbar from "../includes/Navbarwhite";
import bgleft from "../Images/ourstory/bg1left.jpg";
import GoToTop from "./scrolltop";
// import Link from "../includes/Links";
import { Link } from "react-router-dom";
import withPreloader from './indexx';
import Footer from "../includes/footer";

function Ourstory() {
  return (
    <>
      <Navbar />
      <div className="container my-5 py-5">
        <div className="row">
          <div><h1 className="text-end pb-4" style={{fontSize:"60px"}}>Our Story</h1></div>
          <div className="col-lg-5 py-5">
            <img loading="lazy" src={bgleft} className="w-100" alt="" />
          </div>
          <div className="col-lg-7 px-4 py-5">
            <p style={{textAlign: "justify"}}>
              A fragrance is unique to each being, it conveys a different aura
              to everyone. The comfort lies in the scent of the perfume, and the
              unearthing of a gem from the depths of the Karowe diamond mine has
              inspired the Sewelô fragrance line.<br/><br/>
              
              We have created a world of fragrances, each as unique as a
              diamond, reading different on everyone's skin. The journey
              begins with an aroma and at the end leaving you with a myriad
              of memories. Sewelo was created for those who like to stand out
              from the crowd, leave a silent but powerful impression wherever
              they go.<br/><br/>
              
              A unique blend of notes that communicate what words cannot
              express.Indulge in the unique experience,helping you find the
              scent that capture who you truly are.<br/><br/>
              
              The story of perfume is reflected in the shape of the unique
              glass bottle,as rare as the diamond standing out from the crowd
              with a similar texture to rough diamond.
              
            </p>
          </div>
        </div>
        {/* <Link */}
        
        <div className="d-flex justify-content-end"><Link className="navbar-brand " to="/"><p className="border border-dark py-1 px-5">View More</p></Link></div>
        
        

      </div>
      <Footer />
      <GoToTop />
    </>
  );
}

export default withPreloader(Ourstory);
