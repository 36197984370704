import { Link } from "react-router-dom";
import logoblack from "../Images/logo/blacklogo.svg";
import React, {useState, useEffect} from "react";
import axios from 'axios';

function Navbar() {
  const [products,setProducts] = useState([])
  const [asscher,setAsscher] = useState([])
  const [bauguette,setBauguette] = useState([])

  useEffect(() => {
    async function fetchProduct() {
      const {data} = await axios.get('/api/products/')
      const marquisProducts = data.filter(product => product.brand === "MARQUIS");
      const asscherProducts = data.filter(product => product.brand === "ASSCHER");
      const bauguetteProducts = data.filter(product => product.brand === "BAGUETTE");
      setBauguette(bauguetteProducts);
      setProducts(marquisProducts);
      setAsscher(asscherProducts);
      // setProducts(data)
    }
    fetchProduct()
  },[])
  return (
    <>
      <nav
        className="navbar position-absolut border-bottom border-dark  w-100 pt-lg-5"
        style={{ zIndex: 100 }}
      >
        <div className="navbarsection pt-3">
          <Link className="navbar-brand" to="/">
            <img id="logoimage" src={logoblack} alt="SEWELO PARIS"  height="" />
          </Link>
          {/* <Link className="navbar-brand" to="Aboutus">About</Link>
    <Link className="navbar-brand" to="Contactus">Contact</Link> */}
          <button
            id="abcd"
            onClick={openNav}
            type="button"
            className="h1 bi bi-list"
       
          ></button>
        </div>
      </nav>

      <div id="mySidenav" className="sidenav">
        {" "}
        <Link className="closebtn text-white" onClick={closeNav}>
          &times;
        </Link>
        <ul>
        <Link style={{ textDecoration: "none" }} to="/">
            {" "}
            HOME{" "}
          </Link>
          <Link data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample"  style={{ textDecoration: "none" }} to="#collapseExample">
            {" "}
            FRAGRANCE COLLECTIONS{""}<i style={{padding:"10px"}} className="bi bi-chevron-down"></i>
          </Link>
          
          <ul>
          <Link className="collapse" id="collapseExample" style={{ textDecoration: "none" }} to="">
            {" "}
             MARQUIS{" "}
          </Link>
          <ul className="collapse" id="collapseExample">
            {products.map((product) => (
              <Link onClick={closeNav} key={product.id} to={`/Our_Fragrance/Marquis_Colletions/${product.dicrnospace}`}>{product.dicr}</Link>
          ))}
          </ul>


          {Array.isArray(asscher) && asscher.length > 0 ? (
            <Link className="collapse" id="collapseExample" style={{ textDecoration: "none" }} >
            {" "}
            ASSCHER{" "}
            </Link>
          ) : (
            <Link className="collapse" id="collapseExample" style={{ textDecoration: "none" }} to="../Our_Fragrance/Asscher">
            {" "}
            ASSCHER{" "}
            </Link>
          )}
          <ul className="collapse" id="collapseExample">
            {asscher.map((product) => (
              <Link  key={product.id} to={`/Our_Fragrance/Asscher_Colletions/${product.dicrnospace}`} onClick={closeNav}>{product.dicr}</Link>
          ))}
          </ul>
          

          {Array.isArray(bauguette) && bauguette.length > 0 ? (
            <Link className="collapse" id="collapseExample" style={{ textDecoration: "none" }} >
            {" "}
            BAGUETTE{" "}
            </Link>
          ) : (
            <Link className="collapse" id="collapseExample" style={{ textDecoration: "none" }} to="../Our_Fragrance/Bagutte">
            {" "}
            BAGUETTE{" "}
            </Link>
          )}
          <ul className="collapse" id="collapseExample">
            {bauguette.map((product) => (
              <Link  key={product.id} to={`/Our_Fragrance/Bagutte_Colletions/${product.dicrnospace}`} onClick={closeNav}>{product.dicr}</Link>
          ))}
          </ul>

          
          </ul>

          <Link to="../MensAccessories">MEN'S ACCESSORIES</Link>

          <Link to="../WomensBags">WOMEN'S BAGS</Link>

          <Link to="../Openingsoon">BOUTIQUE</Link>

          <Link to="../Ourstory">OUR STORY</Link>

          <Link to="../Contactus">CONTACT US</Link>
        </ul>
      </div>

      <div id="overlay" onClick={closeNav}></div>
    </>
  );
}

export default Navbar;

function overlay(isShow) {
  var elm = document.getElementById("overlay");
  if (isShow) {
    elm.style.display = "block";
  } else {
    elm.style.display = "none";
  }
}

function openNav() {
  overlay(true);
  document.getElementById("mySidenav").style.right = "0px";
}

function closeNav() {
  overlay(false);
  document.getElementById("mySidenav").style.right = "-100vw";
}
