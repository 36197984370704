import React from "react";
import Navbar from "../includes/Navbarwhite";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import GoToTop from "./scrolltop";
import withPreloader from './indexx';
import Footer from "../includes/footer";


const Styledprivacy = styled.section`
// @media screen and (min-width: 601px) and (max-width: 915px) {
//     position: relative;
//     padding-inline: 15vh;
// }
// @media screen and (min-width: 915px) and (max-width: 1200px) {
//     position: relative;
//     padding-inline: 20vh;
// }
// @media screen and (min-width: 1200px) and (max-width: 1300px) {
//     position: relative;
//     padding-inline: 25vh;
// }
// @media screen and (min-width: 1300px) and (max-width: 1400px) {
//     position: relative;
//     padding-inline: 30vh;
// }
// @media screen and (min-width: 1400px) and (max-width: 1450px) {
//     position: relative;
//     padding-inline: 35vh;
// }
// @media screen and (min-width: 1450px) and (max-width: 1550px) {
//     position: relative;
//     padding-inline: 40vh;
// }

  
  //   background-position: "center",
  //   background-size: "cover",
  //   height: "100%",
  //   background-filter:'blur(8px)'
  background-color:white;
  
  .path {
    text-align: right;
    padding: 10px;
    color: LightGray;
  }
  p{
    text-align:center;
    margin-block:20px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.012rem;
    font-size: 18px;
  }
  h2{
    line-height: 1.4;
    font-weight: 450;
    letter-spacing: -0.022rem;
  }
  .mainhead {
    text-align: center;
  }
  .update {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 20px;
  }
  .policy_card {
  }
  .information_we_collect{
    text-align:center;
  }
  .hoe_we_use_your_information{
    text-align:center;
  }
  .security_of_your_information{
    text-align:center;
  }
  .change_to_this_policy{
    text-align:center;
  }
  .contactus{
    text-align:center;
  }
`;
function Privacy() {
  return (
    <div>
      <Navbar />
      <Styledprivacy className="mb-0">
        
        <div className="policy_card container">
        <h6 className="path">HOME / PRIVACY POLICY</h6>
          <h1 className="mainhead">PRIVACY POLICY</h1>
          <p className="update">Last Updated : 22/03/2023 </p>
          <p className="top_content">
            &nbsp;&nbsp;&nbsp;&nbsp;At Sewelo, we respect your privacy and are
            committed to protecting your personal information. This Privacy
            Policy outlines the types of information we collect, how we use that
            information, and the steps we take to ensure that your personal
            information remains secure.
          </p>
          <h2 className="information_we_collect">Information We Collect</h2>
          <p className="second_contend">
            When you visit our website, we may collect certain information about
            you. This information may include your IP address, browser type,
            operating system, and the pages you visit on our website. We may
            also collect information through the use of cookies or similar
            technologies.
          </p>
          <h2 className="hoe_we_use_your_information">
            How We Use Your Information
          </h2>
          <p className="third_contend">
            We use your information to provide you with a better experience on
            our website. This may include customizing the content and layout of
            our website to your preferences and improving our website&#39;s
            functionality.
          </p>
          <p className="third_contend">
            We may also use your information to analyze website usage and
            performance, and to conduct research and surveys to better
            understand our audience. In addition, we may use your information to
            communicate with you about our products and services, or to respond
            to your inquiries or requests.
          </p>
          <p className="third_contend">
            We do not share your personal information with third parties for
            marketing purposes without your explicit consent. However, we may
            share your information with our service providers who help us
            operate our website or provide technical support. We may also
            disclose your information to comply with legal obligations or to
            protect our legal rights.
          </p>
          <h2 className="security_of_your_information">
            Security of Your Information
          </h2>
          <p className="forth_contend">
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, or use. However, no security
            system is completely secure, and we cannot guarantee the security of
            your information.
          </p>
          <h2 className="change_to_this_policy">Changes to This Policy</h2>
          <p className="fifth_contend">
            We may update this Privacy Policy from time to time. If we make any
            material changes to this policy, we will notify you by email or by
            posting a notice on our website.
          </p>
          <h2 className="contactus">Contact Us</h2>
          <p className="sixth_contend mb-0 pb-5">
            If you have any questions or concerns about this Privacy Policy or
            our practices,<br /> please contact us at info@seweloparis.com
          </p>
        </div>
      </Styledprivacy>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default withPreloader(Privacy);
