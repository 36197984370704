import React, { useState,useEffect} from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import { isEmail } from 'validator';
import PhoneInput,{ isValidPhoneNumber,isPossiblePhoneNumber  } from 'react-phone-number-input'

function Enquiry({ face }) {
  const [name, setName] = useState("");
  const [sender, setSender] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [qty, setQty] = useState("");
  const [size, setSize] = useState("");
  const [validnumb, setValidnumb] = useState(false)
  const [validmail, setValidmail] = useState(false)
  // const [messages, setMessages] = useState("");
  const [verified, setVerified] = useState(false);
  // const [error,setError] = useState("");

  useEffect(() => {
    // console.log(validmail,validnumb)
    number && isPossiblePhoneNumber(number)  ? setValidnumb(true) : setValidnumb(false);

    
    if (isEmail(sender)) {
      // Email is valid
      setValidmail(true);
    } else {
      // Email is invalid
      setValidmail(false);
    }
  },[validmail, validnumb, number, sender]);
  function onChange(value) {
    if (value === null) {
      setVerified(false)
    } else {
      setVerified(true)
    }
    
    // console.log("Captcha value:", value);
  }
  const createNote = async (event) => {
    event.preventDefault();
    if (validnumb === false) {
      alert("Phone number is not valid.")
    }
    if (validmail === false) {
      alert("Email is not valid.")
    }
    if (validnumb&&validmail === true) {
    axios({
      method: "post",
      url: "/api/enquiry/",
      data: {
        name: name,
        email: sender,
        message: message,
        number: number,
        address: address,
        qty: qty,
        size: size,
        product: face,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.status);
        if (response.status === 201) {
          alert("mail sent successfully ");
          // console.log(response.status);
        }
      })
      .catch((response) => {
        alert("HTTP_400_BAD_REQUEST Fill out all fields");
        // console.log(response);
      });
    setName("");
    setSender("");
    setMessage("");
    setNumber("");
    setAddress("");
    setQty("");
    setSize("");
    }
  };
  return (
    <div
      className="modal fade modal-md"
      id={"enquirymodal" + face}
      tabIndex="-1"
      aria-labelledby="enquirymodal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="wrapper">
              <form id="contactForm">
                {/* <!-- Name Input --> */}
                <div className="form-floating mb-2">
                  <input
                    className=" form-control  rounded-0"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="name"
                    type="text"
                    placeholder="Name"
                    data-sb-validations="required"
                    required
                  />
                  <label>Your name</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    Name is required.
                  </div>
                </div>

                {/* <!-- Email Input --> */}
                <div className="form-floating mb-2">
                  <input
                    className="form-control rounded-0"
                    value={sender}
                    onChange={(e) => setSender(e.target.value)}
                    name="email"
                    id="emailAddress"
                    type="email"
                    placeholder="Email Address"
                    data-sb-validations="required,email"
                    required
                  />
                  {sender ? <div className="text-danger" >{isEmail(sender) ? '' : 'Email is not valid.'}</div> : ""}

                  <label>Email address</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:required"
                  >
                    Email Address is required.
                  </div>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="emailAddress:email"
                  >
                    Email Address Email is not valid.
                  </div>
                </div>

                <div className="form-floating mb-2">
                  {/* <input
                          className="form-control rounded-0"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          name="number"
                          id="phonenumber"
                          type="tel"
                          placeholder="Phone Number"
                          data-sb-validations="required,phone"
                          required
                        /> */}
                  <PhoneInput
                    className="form-control rounded-0 py-0 d-flex "
                    id="country_inputform1"
                    placeholder="Enter phone number"
                    name="number"
                    value={number}
                    onChange={setNumber}
                    required
                  />
                  {number ? <div className="text-danger" >{number && isValidPhoneNumber(number) ? '' : 'Phone number is not valid.'}</div> : ""}
                  {/* <label>Phone Number</label> */}
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="phonenumber:required"
                  >
                    Phone number is required.
                  </div>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="Phonenumber:number"
                  >
                    Phone number is not valid.
                  </div>
                </div>

                <div className="form-floating mb-2">
                  <textarea
                    className="form-control rounded-0"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"
                    id="address"
                    type="text"
                    placeholder="Address"
                    data-sb-validations="required,address"
                    required
                  />
                  <label>Address</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="address:required"
                  >
                    Address is required.
                  </div>
                </div>

                <div className="form-floating mb-2">
                  <input
                    className="form-control rounded-0"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    name="qty"
                    id="qty"
                    type="text"
                    placeholder="Qty"
                    data-sb-validations="required,qty"
                    required
                  />
                  <label>Qty</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="qty:required"
                  >
                    QTY is required.
                  </div>
                </div>

                <div className="form-floating mb-2">
                  <select
                    className="form-control rounded-0"
                    value={size}
                    name="size"
                    id="size"
                    type="text"
                    placeholder="Size"
                    data-sb-validations="required,size"
                    required
                    onChange={(e) => setSize(e.target.value)}
                  >
                    <option value="">select size</option>
                    <option value="105">105</option>
                    {/* <option value="lime">Lime</option>
                        <option value="coconut">Coconut</option>
                        <option value="mango">Mango</option> */}
                  </select>

                  {/* <input
                          className="form-control rounded-0"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                          name="size"
                          id="size"
                          type="text"
                          placeholder="Size"
                          data-sb-validations="required,size"
                          required
                        /> */}
                  <label>Size</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="size:required"
                  >
                    Size is required.
                  </div>
                </div>

                {/* <!-- Message Input --> */}
                <div className="form-floating mb-2 ">
                  <textarea
                    className="form-control rounded-0"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="message"
                    id="message"
                    type="text"
                    placeholder="Message"
                    data-sb-validations="required"
                    required
                  ></textarea>
                  <label>Message</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    Message is required.
                  </div>
                </div>

                {/* <!-- Submit success message --> */}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center">
                    <div className="fw-bolder">Form submission successful!</div>
                    <p>To activate this form, sign up at</p>
                  </div>
                </div>

                {/* <!-- Submit error message --> */}
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger">
                    Error sending message!
                  </div>
                </div>
                <div className=" form-floating">
                  {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}
                  <ReCAPTCHA
                    sitekey="6LcqKDslAAAAAEpoIukWmrAgltiIiJuZhjBNsHlq"
                    onChange={onChange}
                    data-size="compact"
                    className="g-recaptcha"
                    //  theme='dark'
                    //  size="invisible"
                  />
                </div>

                {/* <div className="buttons-wrapper"> */}
                {/* <!-- Submit button --> */}
                <div className="d-grid form-floating">
                  <button
                    className="btn btn-secondary btn-lg rounded-0 "
                    value="Send"
                    onClick={createNote}
                    id="submitButton"
                    type="submit"
                    disabled={!verified}
                  >
                    Submit
                  </button>
                </div>
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
