import * as React from "react";

const arrowStyleR = {
    // background: "transparent",
    // border: 0,
    color: "#acacac",
    fontSize: "50px",
    marginRight:'-10px',
    position:"absolute",
    right:0
    // float:"right"
  };
const arrowStyleL = {
    // background: "transparent",
    // border: 0,
    color: "#acacac",
    fontSize: "50px",
    marginLeft:'-10px',
    position:"absolute",
    left:0,
  };
const CustomLeftArrow = ({ onClick }) => (
  <i style={arrowStyleL} onClick={() => onClick()} className="bi bi-chevron-left" />
);
const CustomRightArrow = ({ onClick }) => {
  return <i style={arrowStyleR} className="bi bi-chevron-right" onClick={() => onClick()} />;
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group">
      <div>Current slide is {currentSlide}</div>
      <button onClick={() => previous()}>Previous slide</button>
      <button onClick={() => next()}>Next slide</button>
      <button
        onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
      >
        Go to a random slide
      </button>
    </div>
  );
};
const CustomButtonGroupAsArrows = ({ next, previous,carouselState,goToSlide }) => {
  // const { totalItems, currentSlide } = carouselState;
  

  return (
    <div className="custom_button_carousel"
      // style={{
      //   // position:"absolute",
      //   textAlign: "center",
      //   position:"absolute",
      //   width:'107%',
      //   top:'40%',
      //   // left:0,
      //   marginLeft:"-5%"

      // }}
    >
      <i style={arrowStyleL} onClick={previous} className="bi bi-chevron-left" />
      <i style={arrowStyleR} className="bi bi-chevron-right" onClick={next} />

      {/* <h4>These buttons can be positioned anywhere you want on the screen</h4> */}
      {/* <button style={{arrowStyleL}} onClick={previous}>Prev</button> */}
      {/* <button onClick={next}>Next</button> */}
    </div>
  );
};

export {
  CustomLeftArrow,
  CustomRightArrow,
  CustomButtonGroup,
  CustomButtonGroupAsArrows,
};