import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Col, Row } from "react-bootstrap";
// import imggg from "../Images/men/pov.jpg"
// import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const StyledCrossRevealContainer = styled.section`
  // position: relative;
  padding-bottom: 0%; /* to maintain aspect ratio (responsive!) */
  
  .crossRevealImage {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    // padding:0px!important;
  }
  .afterImage {
    position: absolute;
    overflow: hidden;
    top: 0;
    // padding:10vh;
    transform: translate(100%, 0px);
  }
  .afterImage img {
    transform: translate(-100%, 0);
  }
  .person__content {
    color: #080f0f;
    position: absolute;
    top: 45%;
    left: 15vw;
    .person__name {
      font-weight: 700;
      line-height: 1.3;
      font-size: 17px;
    }
    .person__job {
      line-height: 1.3;
      font-weight: 400;
      letter-spacing: -0.022em;
      font-size: 17px;
    }
  }
  .landscape__wrapper {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0 0;
    .quote__sentence {
      line-height: 1.03;
      font-weight: 700;
      font-size: 80px;
      letter-spacing: -0.015em;
      color: #fff;
    }
    .author__name {
      font-weight: 700;
      font-size: 28px;
      line-height: 1.14286;
      letter-spacing: 0;
      color: #fff;
      padding-top: 20px;
    }

  }
`;

function CrossRevealSection({
  face,
  // landscape,
  name,
  discr,
  perfumer,
  perfumername,
  perfumersec,
  // job,
  // sentenceOne,
  // sentenceTwo,
  crossreveal,
}) {
  // animate the container one way...
  const containerRef = useRef(null);
  // ...and the image the opposite way (at the same time)
  const imageRef = useRef(null);
  // specify the point we want our animation to start.
  const triggerRef = useRef(null);
  // target the person container
  const personRef = useRef(null);
  // target the quote container
  // const quoteRef = useRef(null);

  useEffect(() => {
    const crossRevealTween = gsap.timeline({
      defaults: { ease: "power1" },
      scrollTrigger: {
        trigger: triggerRef.current,
        start: "center center",
        scrub: true,
        pin: true,
        // pinSpacer:false,
        end: () => "+=" + triggerRef.current.offsetWidth,
        anticipatePin: 1,
        // markers: true,
      },
    });

    // animate the container one way
    crossRevealTween
      .fromTo(
        containerRef.current,
        { [crossreveal]: 100, x: 0 },
        { [crossreveal]: 0 }
      )
      // and animate the image the opposite way at the same time
      .fromTo(
        imageRef.current,
        { [crossreveal]: -100, x: 0 },
        { [crossreveal]: 0 },
        0
      )
      // // fade in the the name and the job
      // .from(personRef.current, { autoAlpha: 0, delay: 0.1 }, 0)
      // // fade in the quote
      // .from(quoteRef.current, { autoAlpha: 0, delay: 0.1 }, 0);
  }, [crossreveal]);

  return (
    <StyledCrossRevealContainer ref={triggerRef} id="crosserv">
      <div className="crossRevealImage" >
        <img src={face} alt=".." preload="true" />
        <div className="person__content" ref={personRef}>
        </div>
        
      </div>
      <div style={{display:"flex"}} className="crossRevealImage afterImage" ref={containerRef}>
        <Row ref={imageRef}  >
          <Col sm={12} md={4} lg={4} xl={4}  style={{
          backgroundImage: `url(${face})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "100vh",
        }}>
        <div className="position-absolute bottom-0 start-0 marquis_div " >
          <div className=" mx-5 d-flex " style={{margin:"1rem"}}>
            <div className="position-relative ">
              <h4 className="px-2 pt-2 fonttext mb-0 border-start text-white border-end border-top slideranimation_boxtext">
                MARQUIS
              </h4>
              <div className="row g-0">
                <div className="col border-start border-bottom h-50 pt-1"></div>
                <div className="col border-botto h-50 ">
                  <h6 className="px-1 fonttext text-white" style={{fontSize:".7rem"}}>Collections</h6>
                </div>
                <div className="col border-bottom h-50 border-end pt-1"></div>
              </div>
            </div>
          </div>
        </div>
          </Col>
          <Col sm={12} md={8} lg={8} xl={8}   style={{backgroundColor:"white",textAlign:"right"}}>
          <div className="containe" style={{ textAlign:"right"}}>
              <p className="p-5 slideranimation_boxtext_white" >Sewelo/ Our Fragrance/ Marquis Colletions</p>
              
          </div>
          <div style={{position:"relative"}}>
          <img className="px-5 crossimgs"  src={name}  style={{filter: 'invert(1)',objectFit:"contain",width:"35%",height:"fit-content", top:"auto",marginTop:"2rem"}} alt="" />
          </div>
          <div style={{position:"relative"}}>
          <p className="mx-5 crossptag" style={{marginTop:"24%",textAlign:"justify"}}   >{discr}</p>

          </div>
          {/* <div style={{position:"relative"}}></div> */}
          {/* maker_details */}
          <div className="position-absolute me-5 px-3 bottom-0  end-0 my-3 d-none d-lg-block " style={{height:'60px',textAlign:'left'}} >
            <p className="my-0" style={{fontSize:'.6rem'}}>Perfumer</p>
            <p className="my-0" style={{fontSize:'.9rem'}}>{perfumername}</p>
            <p className="my-0" style={{fontSize:'.6rem'}}>{perfumersec}</p>
            <p className="pfor_profil" ><img src={perfumer} style={{width:'50px',height:'50px',borderRadius:'50%',left:0}} alt="loading" /></p>
          </div>
          <div style={{textAlign:'left'}} className="position-absolute bottom-0 text-left  me-4 d-none d-lg-block">
          {/* <p className="border px-3 py-2 fonttext text-dark border-dark p-1  m-5">
            link
          </p> */}
          {/* <p style={{margin:0}} className="border my-2 px-3 py-2 mx-5 fonttext text-dark border-dark slideranimation_boxtext_white ">
              Eau De Parfum
          </p> */}
          <button  type="button" className="mx-5 my-4 px-3  btn btn-outline-dark rounded-0 " data-bs-toggle="modal" data-bs-target="#exampleModal">
            <p className="slideranimation_boxtext_white" style={{margin:0}}>VIEW 360&deg;</p>
          </button>
          
          
           {/* Modal */}
           
          </div>
          </Col>
        </Row>
      </div>
      
    </StyledCrossRevealContainer>
  );
}

export default CrossRevealSection;
