import React, { useEffect,useRef } from "react";
// import { Card  } from 'react-bootstrap'
import { Link } from 'react-router-dom';
// import name from "../Images/product/Perfume names-05.png"
// import bg from "../assets/green.jpg";
import ThreeSixty from '../utils/threesixty';


const width = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      wd:439.76
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      wd:766.55
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      wd:766.55
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      wd: 400
    }
  };

function Item({product}) {
  const [color, setColor] = React.useState("none");
  const styles = {
    display:color,
    height: '200px',
  //   objectFit:"contain",
    // backgroundImage: `url(${product.thumb})`,
    // backgroundPosition: "center",

    // objectFit:"cover",
    // position: 'absolute',
  };
  const [colors, setColors] = React.useState("");
  const styless = {
    display:colors,
    // margin:'auto',
    
    
    // position: 'absolute',
  };
  const focusPoint = useRef(null);
  // const removePoint = useRef(null);
  useEffect(() => {
    
     new ThreeSixty(document.getElementById(product.id), {
      image:product.thumb360,
      width:width.wd,
      height:200,
      count:36,
      perRow: 4,
      // prev: document.getElementById('prev' + product.id), // Previous button element. Default: null
      // next: document.getElementById('next' + product.id), // Next button element. Default: null
      keys: true,         // Rotate image on arrow keys. Default: true
      draggable: true,    // Rotate image by dragging. Default: true
      swipeable: true,    // Rotate image by swiping on mobile screens. Default: true
      dragTolerance: 5,  // Rotation speed when dragging. Default: 10
      swipeTolerance: 5, // Rotation speed when swiping. Default: 10
      swipeTarget: document.getElementById('wrapper'+product.id), // Element which will listen for drag/swipe events. Default: Image container
    
      // Rotation settings
      speed: 10,     // Rotation speed during 'play' mode. Default: 10
      inverted: true // Inverts rotation direction
    });
    // onMouseEnter={(e) => } onMouseLeave={(e) => setColor("")}
    focusPoint.current.addEventListener( 'mouseover',(e) => setColor("none") );
    focusPoint.current.addEventListener( 'mouseover',(e) => setColors("") );
    focusPoint.current.addEventListener( 'mouseout',(e) => setColor("initial") );
    focusPoint.current.addEventListener( 'mouseout',(e) => setColors("none") );
    // console.log(color,colors)
    // threesixty.toggle();
    // threesixty.play();
  });
  return (
    <div ref={focusPoint} className="mb-5 pb-2">
    <div  className=" pb-1 mb-4  shadow">
    <Link  className='text-decoration-none text-dark ' key={product.id}  to={`/Our_Fragrance/Marquis_Colletions/${product.dicrnospace}`} >
      <div    className={"wrapper"+product.id}>
        {/* <h1>{product.decr}</h1> */}
        {/* <p>
          <a className="g-hub" href="https://github.com/mladenilic/threesixty" target="_blank">Check out ThreeSixty.js on Github</a>
        </p> */}
        {/* <img src={product.thumb} alt="loop" /> */}
        {/* <div style={styles} id="thumb" className="w-100"></div> */}
        {/* <img   style={styles} id="thumb" className="w-100" src={product.thumb} alt="" /> */}
        
        <img   style={styles} id="thumb" className="w-100" src={product.thumb} alt="" />
        <div  style={styless} id={product.id}></div>
        
        {/* <div className="buttons-wrapper">
          <button className="btn btn-light" id={"prev"+product.id}><i className="bi bi-chevron-double-left"></i></button>
          <button className="btn btn-light" id={"next"+product.id}><i className="bi bi-chevron-double-right"></i></button>
        </div> */}
      </div>
      </Link>
      <div className="text-center mb-4">
        {/* <h2 className='mb-0' style={{fontSize:"20px",width:"objectFit"}}>{product.name}</h2> */}
        <div style={{width:'70%', margin:'auto'}}>
        <img src={product.nameimg}  style={{display:"initial",filter: 'invert(1)',objectFit:"cover",width:"100% ",height:"10%"}} alt="" />
        </div>
        <Link  className='text-decoration-none text-dark ' key={product.id} to={`/Our_Fragrance/Marquis_Colletions/${product.dicrnospace}`} >
          <h6
          style={{ width: "fit-content" }}
          className="px-4 btn btn-outline-dark mx-auto border rounded-0 bordr-2 border-dark"
        >LEARN MORE</h6>
        </Link>

        {/* <Link  className='text-decoration-none text-dark ' to={`/products/${product.id}/`}>
          <h6
          style={{ width: "fit-content" }}
          className="px-5 btn btn-outline-dark mx-auto border rounded-0 bordr-2 border-dark"
        >Enquire now</h6>
        </Link> */}

      </div>
    </div>
    </div>
  );
}

export default Item
