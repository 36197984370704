import Home from "./components/pages/Home";
// import Homie from "./components/pages/Homie";
// import React, { useState } from "react";
// import Navbar from "./components/includes/Navbar";
// import Footer from "./components/includes/footer";
import Contactus from "./components/pages/Contactc";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Ourstory from "./components/pages/Ourstory";
import Baugutte from "./components/pages/baugute";
import Asscher from "./components/pages/assher";
import Product from "./components/pages/product";
// import Productdemo from "./components/pages/productdemo";
import MensAccessories from "./components/pages/men";
import WomensBags from "./components/pages/women";
import Openingsoon from "./components/pages/comingsoon";
import Privacy from "./components/pages/Privacy";
import Cookies from "./components/pages/Cookies";
import Tcpage from "./components/pages/tcpage";
import Termscond from "./components/pages/termscon";
import Faq from "./components/pages/faq";
import Blog from "./components/pages/Blog";
import CookieConsentBanner from "./components/includes/CookieConsentBanner";
// import Indexx from "./components/pages/indexx"

function App() {
  // const [loading, setLoading] = useState(true);
  // const spinner = document.getElementById("spinner");
  // if (spinner) {
  //   setTimeout(() => {
  //     spinner.style.display = "none";
  //     setLoading(false);
  //   }, 4000);
  // }
  return (
    // !loading && (
    // <Router basename="/alpha">
    <Router >
      <Routes>
        
          {/* <Route path="/" element={<Indexx />} exact /> */}
          <Route path="/"  element={<Home />} exact />
          {/* <Route path="/Homie"  element={<Homie />} exact /> */}
          {/* <Route path="alpha/" element={<Home />} exact /> */}
          <Route path="Ourstory" element={<Ourstory />} />
          <Route path="Our_Fragrance/Marquis_Colletions/:name" element={<Product />} />
          <Route path="Our_Fragrance/Asscher_Colletions/:name" element={<Product />} />
          <Route path="Our_Fragrance/Bagutte_Colletions/:name" element={<Product />} />
          {/* <Route path="productdemo/:id" element={<Productdemo />} /> */}
          <Route path="Contactus" element={<Contactus />} />
          <Route path="MensAccessories" element={<MensAccessories/>} />
          <Route path="WomensBags" element={<WomensBags/>} />
          <Route path="Openingsoon" element={<Openingsoon/>} />
          <Route path="Blog" element={<Blog />} />
          <Route path="Contactus" element={<Contactus/>} />
          <Route path="Our_Fragrance/Bagutte" element={<Baugutte/>} />
          <Route path="Our_Fragrance/Asscher" element={<Asscher/>} />
          <Route path="Privacy" element={<Privacy/>} />
          <Route path="Cookies" element={<Cookies/>} />
          <Route path="Terms_of_use" element={<Tcpage/>} />
          <Route path="Terms_condition" element={<Termscond />} />
          <Route path="FAQ" element={<Faq />} />

      </Routes>
      {/* <Footer /> */}
      <CookieConsentBanner />
    </Router>
    )
  // );
}

export default App;
