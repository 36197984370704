import image_0000 from "../assets/sewelo/Sequence01000.jpg";
import image_0001 from "../assets/sewelo/Sequence01001.jpg";
import image_0002 from "../assets/sewelo/Sequence01002.jpg";
import image_0003 from "../assets/sewelo/Sequence01003.jpg";
import image_0004 from "../assets/sewelo/Sequence01004.jpg";
import image_0005 from "../assets/sewelo/Sequence01005.jpg";
import image_0006 from "../assets/sewelo/Sequence01006.jpg";
import image_0007 from "../assets/sewelo/Sequence01007.jpg";
import image_0008 from "../assets/sewelo/Sequence01008.jpg";
import image_0009 from "../assets/sewelo/Sequence01009.jpg";
import image_0010 from "../assets/sewelo/Sequence01010.jpg";
import image_0011 from "../assets/sewelo/Sequence01011.jpg";
import image_0012 from "../assets/sewelo/Sequence01012.jpg";
import image_0013 from "../assets/sewelo/Sequence01013.jpg";
import image_0014 from "../assets/sewelo/Sequence01014.jpg";
import image_0015 from "../assets/sewelo/Sequence01015.jpg";
import image_0016 from "../assets/sewelo/Sequence01016.jpg";
import image_0017 from "../assets/sewelo/Sequence01017.jpg";
import image_0018 from "../assets/sewelo/Sequence01018.jpg";
import image_0019 from "../assets/sewelo/Sequence01019.jpg";
import image_0020 from "../assets/sewelo/Sequence01020.jpg";
import image_0021 from "../assets/sewelo/Sequence01021.jpg";
import image_0022 from "../assets/sewelo/Sequence01022.jpg";
import image_0023 from "../assets/sewelo/Sequence01023.jpg";
import image_0024 from "../assets/sewelo/Sequence01024.jpg";
import image_0025 from "../assets/sewelo/Sequence01025.jpg";
import image_0026 from "../assets/sewelo/Sequence01026.jpg";
import image_0027 from "../assets/sewelo/Sequence01027.jpg";
import image_0028 from "../assets/sewelo/Sequence01028.jpg";
import image_0029 from "../assets/sewelo/Sequence01029.jpg";
import image_0030 from "../assets/sewelo/Sequence01030.jpg";
import image_0031 from "../assets/sewelo/Sequence01031.jpg";
import image_0032 from "../assets/sewelo/Sequence01032.jpg";
import image_0033 from "../assets/sewelo/Sequence01033.jpg";
import image_0034 from "../assets/sewelo/Sequence01034.jpg";
import image_0035 from "../assets/sewelo/Sequence01035.jpg";
import image_0036 from "../assets/sewelo/Sequence01036.jpg";
import image_0037 from "../assets/sewelo/Sequence01037.jpg";
import image_0038 from "../assets/sewelo/Sequence01038.jpg";
import image_0039 from "../assets/sewelo/Sequence01039.jpg";
import image_0040 from "../assets/sewelo/Sequence01040.jpg";
import image_0041 from "../assets/sewelo/Sequence01041.jpg";
import image_0042 from "../assets/sewelo/Sequence01042.jpg";
import image_0043 from "../assets/sewelo/Sequence01043.jpg";
import image_0044 from "../assets/sewelo/Sequence01044.jpg";
import image_0045 from "../assets/sewelo/Sequence01045.jpg";
import image_0046 from "../assets/sewelo/Sequence01046.jpg";
import image_0047 from "../assets/sewelo/Sequence01047.jpg";
import image_0048 from "../assets/sewelo/Sequence01048.jpg";
import image_0049 from "../assets/sewelo/Sequence01049.jpg";
import image_0050 from "../assets/sewelo/Sequence01050.jpg";
import image_0051 from "../assets/sewelo/Sequence01051.jpg";
import image_0052 from "../assets/sewelo/Sequence01052.jpg";
import image_0053 from "../assets/sewelo/Sequence01053.jpg";
import image_0054 from "../assets/sewelo/Sequence01054.jpg";
import image_0055 from "../assets/sewelo/Sequence01055.jpg";
import image_0056 from "../assets/sewelo/Sequence01056.jpg";
import image_0057 from "../assets/sewelo/Sequence01057.jpg";
import image_0058 from "../assets/sewelo/Sequence01058.jpg";
import image_0059 from "../assets/sewelo/Sequence01059.jpg";
import image_0060 from "../assets/sewelo/Sequence01060.jpg";
import image_0061 from "../assets/sewelo/Sequence01061.jpg";
import image_0062 from "../assets/sewelo/Sequence01062.jpg";
import image_0063 from "../assets/sewelo/Sequence01063.jpg";
import image_0064 from "../assets/sewelo/Sequence01064.jpg";
import image_0065 from "../assets/sewelo/Sequence01065.jpg";
import image_0066 from "../assets/sewelo/Sequence01066.jpg";
import image_0067 from "../assets/sewelo/Sequence01067.jpg";
import image_0068 from "../assets/sewelo/Sequence01068.jpg";
import image_0069 from "../assets/sewelo/Sequence01069.jpg";
import image_0070 from "../assets/sewelo/Sequence01070.jpg";
import image_0071 from "../assets/sewelo/Sequence01071.jpg";
import image_0072 from "../assets/sewelo/Sequence01072.jpg";
import image_0073 from "../assets/sewelo/Sequence01073.jpg";
import image_0074 from "../assets/sewelo/Sequence01074.jpg";
import image_0075 from "../assets/sewelo/Sequence01075.jpg";
import image_0076 from "../assets/sewelo/Sequence01076.jpg";
import image_0077 from "../assets/sewelo/Sequence01077.jpg";
import image_0078 from "../assets/sewelo/Sequence01078.jpg";
import image_0079 from "../assets/sewelo/Sequence01079.jpg";
import image_0080 from "../assets/sewelo/Sequence01080.jpg";
import image_0081 from "../assets/sewelo/Sequence01081.jpg";
import image_0082 from "../assets/sewelo/Sequence01082.jpg";
import image_0083 from "../assets/sewelo/Sequence01083.jpg";
import image_0084 from "../assets/sewelo/Sequence01084.jpg";
import image_0085 from "../assets/sewelo/Sequence01085.jpg";
import image_0086 from "../assets/sewelo/Sequence01086.jpg";
import image_0087 from "../assets/sewelo/Sequence01087.jpg";
import image_0088 from "../assets/sewelo/Sequence01088.jpg";
import image_0089 from "../assets/sewelo/Sequence01089.jpg";
import image_0090 from "../assets/sewelo/Sequence01090.jpg";
import image_0091 from "../assets/sewelo/Sequence01091.jpg";
import image_0092 from "../assets/sewelo/Sequence01092.jpg";
import image_0093 from "../assets/sewelo/Sequence01093.jpg";
import image_0094 from "../assets/sewelo/Sequence01094.jpg";
import image_0095 from "../assets/sewelo/Sequence01095.jpg";
import image_0096 from "../assets/sewelo/Sequence01096.jpg";
import image_0097 from "../assets/sewelo/Sequence01097.jpg";
import image_0098 from "../assets/sewelo/Sequence01098.jpg";
import image_0099 from "../assets/sewelo/Sequence01099.jpg";
import image_0100 from "../assets/sewelo/Sequence01100.jpg";
import image_0101 from "../assets/sewelo/Sequence01101.jpg";
import image_0102 from "../assets/sewelo/Sequence01102.jpg";
import image_0103 from "../assets/sewelo/Sequence01103.jpg";
import image_0104 from "../assets/sewelo/Sequence01104.jpg";
import image_0105 from "../assets/sewelo/Sequence01105.jpg";
import image_0106 from "../assets/sewelo/Sequence01106.jpg";
import image_0107 from "../assets/sewelo/Sequence01107.jpg";
import image_0108 from "../assets/sewelo/Sequence01108.jpg";
import image_0109 from "../assets/sewelo/Sequence01109.jpg";
import image_0110 from "../assets/sewelo/Sequence01110.jpg";
import image_0111 from "../assets/sewelo/Sequence01111.jpg";
import image_0112 from "../assets/sewelo/Sequence01112.jpg";
import image_0113 from "../assets/sewelo/Sequence01113.jpg";
import image_0114 from "../assets/sewelo/Sequence01114.jpg";

export const iphoneimages = [
  image_0000,
  image_0001,
  image_0002,
  image_0003,
  image_0004,
  image_0005,
  image_0006,
  image_0007,
  image_0008,
  image_0009,
  image_0010,
  image_0011,
  image_0012,
  image_0013,
  image_0014,
  image_0015,
  image_0016,
  image_0017,
  image_0018,
  image_0019,
  image_0020,
  image_0021,
  image_0022,
  image_0023,
  image_0024,
  image_0025,
  image_0026,
  image_0027,
  image_0028,
  image_0029,
  image_0030,
  image_0031,
  image_0032,
  image_0033,
  image_0034,
  image_0035,
  image_0036,
  image_0037,
  image_0038,
  image_0039,
  image_0040,
  image_0041,
  image_0042,
  image_0043,
  image_0044,
  image_0045,
  image_0046,
  image_0047,
  image_0048,
  image_0049,
  image_0050,
  image_0051,
  image_0052,
  image_0053,
  image_0054,
  image_0055,
  image_0056,
  image_0057,
  image_0058,
  image_0059,
  image_0060,
  image_0061,
  image_0062,
  image_0063,
  image_0064,
  image_0065,
  image_0066,
  image_0067,
  image_0068,
  image_0069,
  image_0070,
  image_0071,
  image_0072,
  image_0073,
  image_0074,
  image_0075,
  image_0076,
  image_0077,
  image_0078,
  image_0079,
  image_0080,
  image_0081,
  image_0082,
  image_0083,
  image_0084,
  image_0085,
  image_0086,
  image_0087,
  image_0088,
  image_0089,
  image_0090,
  image_0091,
  image_0092,
  image_0093,
  image_0094,
  image_0095,
  image_0096,
  image_0097,
  image_0098,
  image_0099,
  image_0100,
  image_0101,
  image_0102,
  image_0103,
  image_0104,
  image_0105,
  image_0106,
  image_0107,
  image_0108,
  image_0109,
  image_0110,
  image_0111,
  image_0112,
  image_0113,
  image_0114,
];
