import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myCookieConsent"
      style={{ background: '#333333' }}
      buttonStyle={{ color: '#fff', background: '#888' }}
      expires={30}
    >
      This website uses cookies to enhance the user experience. By using this site, you consent to the use of cookies.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
