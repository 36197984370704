import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

function Carouselss() {
  const [product, setProduct] = useState([]);
  const pramas = useParams();
  useEffect(() => {
    async function fetchProduct() {
      const { data } = await axios.get(`/api/products/${pramas.name}`);
      // console.log(data);
      setProduct(data);
    }
    fetchProduct();
  }, [pramas.name]);
  return (
    <>
      <Carousel variant="dark" fade>
        <Carousel.Item interval={2000}>
          <img
            loading="lazy"
            src={product.png1}
            style={{ objectFit: "cover", width: "100vw" }}
            className="mx-auto d-block pdimage "
            alt="..."
          />
          <div className="circle_on_img position-absolute top-50 start-50 translate-middle  border border-white">
            <div className="circle_filled_on_img position-absolute p-5 top-50 start-50 translate-middle carousel-caption  border border-dark bg-dark">
              <div>
                <h1 className="circle_text_h1 pt-4 fonttext mt-5 text-warning">
                  TOP NOTE
                </h1>
                <h4 className="circle_text_h4 text-light fonttext">
                  {product.topnote}
                </h4>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
              loading="lazy"
              src={product.png2}
              style={{ objectFit: "cover", width: "100vw" }}
              className="mx-auto d-block pdimage "
              alt="..."/>
           <div className="circle_on_img position-absolute top-50 start-50 translate-middle  border border-white">
            <div className="circle_filled_on_img position-absolute p-5 top-50 start-50 translate-middle carousel-caption  border border-white bg-dark">
              <div>
                <h1 className="circle_text_h1 pt-4  mt-4 fonttext text-warning">
                  HEART NOTE
                </h1>
                <h4 className="circle_text_h4 fonttext text-light">
                  {product.heartnote}
                </h4>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
              src={product.png3}
              loading="lazy"
              style={{ objectFit: "cover", width: "100vw" }}
              className="mx-auto d-block pdimage "
              alt="..."/>
          <div className="circle_on_img position-absolute top-50 start-50 translate-middle  border border-white">
            <div className="circle_filled_on_img position-absolute p-5 top-50 start-50 translate-middle carousel-caption  border border-white bg-dark">
              <div>
                <h1 className="circle_text_h1 pt-4 fonttext mt-5 text-warning">
                  BASE NOTE
                </h1>
                <h4 className="circle_text_h4 text-light fonttext">
                  {product.basenote}
                </h4>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Carouselss;
