import React from "react";
import Navbar from "../includes/Navbarwhite";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import GoToTop from "./scrolltop";
import withPreloader from './indexx';
import Footer from "../includes/footer";

const StyledCrossRevealContainer = styled.section`
  // @media screen and (min-width: 601px) and (max-width: 915px) {
  //     position: relative;
  //     padding-inline: 15vh;
  // }
  // @media screen and (min-width: 915px) and (max-width: 1200px) {
  //     position: relative;
  //     padding-inline: 20vh;
  // }
  // @media screen and (min-width: 1200px) and (max-width: 1300px) {
  //     position: relative;
  //     padding-inline: 25vh;
  // }
  // @media screen and (min-width: 1300px) and (max-width: 1400px) {
  //     position: relative;
  //     padding-inline: 30vh;
  // }
  // @media screen and (min-width: 1400px) and (max-width: 1450px) {
  //     position: relative;
  //     padding-inline: 35vh;
  // }
  // @media screen and (min-width: 1450px) and (max-width: 1550px) {
  //     position: relative;
  //     padding-inline: 40vh;
  // }
,  //   background-position: "center"6,7,12,13,18,20,16,17,21
  //   background-size: "cover",
  //   height: "100%",
  //   background-filter:'blur(8px)'
  background-color: white;

  .path {
    text-align: right;
    padding: 10px;
    color: LightGray;
  }
  p {
    text-align: center;
    margin-block: 20px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.012rem;
    font-size: 18px;
  }
  h2 {
    line-height: 1.4;
    font-weight: 450;
    letter-spacing: -0.022rem;
  }
  .mainhead {
    text-align: center;
  }
  .update {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 20px;
  }
  .policy_card {
  }
  .information_we_collect {
    text-align: center;
  }
  .hoe_we_use_your_information {
    text-align: center;
  }
  .security_of_your_information {
    text-align: center;
  }
  .change_to_this_policy {
    text-align: center;
  }
  .contactus {
    text-align: center;
  }
`;
function Tcpage() {
  return (
    <div>
      <Navbar />
      <StyledCrossRevealContainer className="mb-0">
        <div className="policy_card container">
          <h6 className="path">HOME / Terms of Use</h6>
          <h1 className="mainhead">Terms of Use</h1>
          <p className="update">Last Updated : 22/03/2023 </p>
          <p className="top_content">
            &nbsp;&nbsp;&nbsp;&nbsp;Welcome to seweloparis.com By accessing our
            website, you agree to be bound by the following terms of use (the
            &quot;Terms&quot;). If you do not agree to these Terms, please do
            not use our website.
          </p>
          <h2 className="information_we_collect">
            Content and Intellectual Property
          </h2>
          <p className="second_contend">
            All content on our website, including text, images, and logos, is
            the property of [Your Perfume Informational Website] and is
            protected by copyright laws. You may not reproduce, distribute,
            modify, or otherwise use any of our content without our prior
            written consent.
          </p>
          <h2 className="hoe_we_use_your_information">
            Accuracy of Information
          </h2>
          <p className="third_contend">
            We strive to provide accurate and up-to-date information on our
            website. However, we make no warranties or representations as to the
            accuracy or completeness of the information we provide. We are not
            responsible for any errors, omissions, or inaccuracies in our
            content.
          </p>
          {/* <p className="third_contend">
            First-party cookies are created and controlled by us. We use these
            cookies to provide you with a personalized experience on our
            website, remember your preferences, and keep you logged in.
          </p> */}
          {/* <p className="third_contend">
            Third-party cookies are created and controlled by third-party
            providers, such as Google Analytics or social media platforms. We
            use these cookies to collect data about your behavior on our
            website, such as which pages you visit and how long you stay on each
            page. This data helps us improve our website&#39;s performance and
            provide you with personalized content and advertisements.
          </p> */}
          <h2 className="security_of_your_information">
            Links to Third-Party Websites
          </h2>
          <p className="forth_contend">
            Our website may contain links to third-party websites. We are not
            responsible for the content or practices of these websites. By
            accessing these links, you agree to be bound by the terms and
            conditions of the third-party website.
          </p>
          {/* <p className="forth_contend">
            If you wish to opt-out of third-party cookies, you can do so by
            visiting the Network Advertising Initiative&#39;s opt-out page or
            the Digital Advertising Alliance&#39;s opt-out page.
          </p> */}
          <h2 className="change_to_this_policy">Prohibited Uses</h2>
          <p className="fifth_contend">
            You may not use our website for any unlawful purpose or in violation
            of any local, state, national, or international law. You may not use
            our website to solicit others to perform or participate in any
            unlawful acts. You may not use our website to harass, abuse, insult,
            harm, defame, slander, disparage, intimidate, or discriminate based
            on gender, sexual orientation, religion, ethnicity, race, age,
            national origin, or disability.
          </p>
          <h2 className="contactus">Termination</h2>
          <p className="sixth_contend  ">
            We reserve the right to terminate your use of our website at any
            time and for any reason, including, but not limited to, your
            violation of these Terms.
          </p>
          <h2 className="contactus">Disclaimer of Warranties</h2>
          <p className="sixth_contend  ">
          Our website is provided &quot;as is&quot; and without warranties of any kind, whether express or implied.
We do not guarantee that our website will be error-free or uninterrupted, or that any defects
will be corrected. We do not make any warranties or representations regarding the use or
results of our website in terms of accuracy, reliability, or otherwise.
          </p>
          <h2 className="contactus">Limitation of Liability</h2>
          <p className="sixth_contend  ">
          We will not be liable for any direct, indirect, incidental, consequential, or punitive damages
arising out of your use of our website or your inability to use our website. This includes any
damages resulting from errors, mistakes, or inaccuracies on our website.
          </p>
          <h2 className="contactus">Governing Law</h2>
          <p className="sixth_contend  ">
          These Terms shall be governed by and construed in accordance with Dubai Law, without regard
to its conflict of law provisions.
          </p>
          <h2 className="contactus">Changes to these Terms</h2>
          <p className="sixth_contend ">
          We reserve the right to modify these Terms at any time without prior notice. By continuing to
use our website after any changes are made to these Terms, you agree to be bound by the
modified Terms.
          </p>
          <h2 className="contactus">Contact Us</h2>
          <p className="sixth_contend pb-5">
          If you have any questions or concerns about these Terms or our practices, please contact us at
infor@seweloparis.com
          </p>
        </div>
      </StyledCrossRevealContainer>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default withPreloader(Tcpage);
